<template>
  <footer id="footer">
    <back-to-top class="back-to-top"></back-to-top>
    <FooterDeliverySeparator />
    <div class="container">
      <div
        v-if="accdata.length > 0"
        class="d-flex flex-column flex-md-row flex-wrap justify-content-start flex-container flex-column flex-md-row"
      >
        <div
          class="accordion-block"
          v-for="(accdatum, index) in accdata"
          :key="index"
        >
          <b-link
            href="#"
            class="text-uppercase acc-opener block-heading"
            v-b-toggle="index.toString()"
            v-if="typeof accdatum != 'undefined'"
            >{{ accdatum.title }}</b-link
          >
          <FooterAccordion :accordionData="accdatum" :index="index" />
        </div>

        <div class="contacts-block" v-if="typeof contactData == 'object'">
          <span class="text-uppercase block-heading">
            {{ contactData.title }}
          </span>
          <cmsBlockSimple
            v-if="typeof contactData == 'object'"
            :identifier="contactData.identifier"
          />
        </div>
        <div class="social-block" v-if="socialIcons">
          <span class="text-uppercase block-heading">Volg ons:</span>
          <ul class="social-icons">
            <li v-for="socialIcon in socialIcons" :key="socialIcon.id">
              <b-link :href="socialIcon.linkTo" target="_blank">
                <font-awesome-icon
                  :icon="['fab', socialIcon.iconName]"
                ></font-awesome-icon>
              </b-link>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="d-flex flex-column flex-md-row flex-container delivery-and-payment"
      >
        <div class="payment">
          <span class="text-uppercase block-heading d-block">{{
            $t("secure_payments")
          }}</span>
          <div v-if="typeof paymentPartners == 'object'">
            <ul class="payment-partners">
              <li v-for="(image, index) in paymentPartners.slides" :key="index">
                <b-link class="img" :href="image.link" target="_blank">
                  <img
                    :src="image.media.url"
                    :alt="image.title"
                    width="40"
                    height="25"
                  />
                </b-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="delivery">
          <span class="text-uppercase block-heading d-block">
            {{ $t("delivered_by") }}
          </span>
          <div v-if="typeof deliveryPartners == 'object'">
            <ul class="delivery-partners">
              <li
                v-for="(image, index) in deliveryPartners.slides"
                :key="index"
              >
                <b-link class="img" :href="image.link" target="_blank">
                  <img
                    :src="image.media.url"
                    :alt="image.title"
                    width="40"
                    height="25"
                  />
                </b-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterDeliverySeparator from "@/esf_lommel_dt1_europe/core/components//FooterDeliverySeparator";
import FooterAccordion from "@/esf_lommel_dt1_europe/core/components//FooterAccordion";
import cmsBlockSimple from "@/esf_lommel_dt1_europe/core/components//core/BlockSimple";
import BackToTop from "@/esf_lommel_dt1_europe/core/components//core/BackToTop";

export default {
  name: "Footer",
  components: {
    FooterDeliverySeparator,
    FooterAccordion,
    cmsBlockSimple,
    BackToTop,
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    accdata() {
      return [
        this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "footer_menu_information"
        ),
        this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "footer_menu_service"
        ),
      ];
    },
    deliveryPartners() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "delivery_partners"
      );
    },
    paymentPartners() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "payment_providers"
      );
    },
    contactData() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_contact"
      );
    },
  },
  data: () => ({
    socialIcons: [
      //use font awesome icon names
      //linkTo format: "https://www.google.com"
      {
        id: "socialIcon1",
        iconName: "instagram",
        linkTo: "https://www.instagram.com/dt1mxpark/",
      },
      {
        id: "socialIcon2",
        iconName: "facebook-square",
        linkTo: "https://nl-be.facebook.com/DT1Europe/",
      },
      //{ id: "socialIcon4", iconName: "twitter-square", linkTo: "#" },
      //{ id: "socialIcon3", iconName: "google-plus-square", linkTo: "#" },
    ],
  }),
};
</script>

<style lang="scss" scoped>
//@import "@/esf_lommel_dt1_europe/scss/main.scss";
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/custom-bootstrap.scss";
@import "@/esf_lommel_dt1_europe/scss/mixins.scss";

.back-to-top {
  @include animate(opacity);
  width: 48px;
  height: 48px;
  background: #000;
  border-radius: 100%;
  font-size: 0;
  line-height: 0;
  // position: absolute;
  // bottom: auto;
  // top: -40px;
  // right: 40px !important;

  // @include media('screen', '>=desktop') {
  //   top: -60px;
  // }

  @include media("screen", ">=#{map-get($container-max-widths, xl)}") {
    right: calc((100vw - #{map-get($container-max-widths, xl)}) / 4) !important;
  }

  &:after {
    content: "";
    width: 18px;
    height: 18px;
    border-top: 4px solid #fff;
    border-right: 4px solid #fff;
    transform: translate(-50%, -50%) rotate(-45deg);
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: 4px;
  }

  &:hover {
    opacity: 0.7;
  }
}

#footer {
  position: relative;
  padding-top: 44px;
  padding-bottom: 5px;
  position: relative;
  @include media("screen", ">=desktop") {
    padding-top: 0;
    padding-bottom: 45px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 39px;
    left: -9999px;
    right: -9999px;
    background: #000;
    height: 5px;

    @include media("screen", ">=tablet") {
      top: 20px;
    }

    @include media("screen", ">=desktop") {
      content: none;
    }
  }
}
.acc-opener {
  padding: 12px 0 !important;
  position: relative;
  color: #000 !important;
  @include media("screen", ">=tablet") {
    padding: 0 !important;
    pointer-events: none;
  }

  &:before {
    content: "\e874";
    font-family: "Linearicons-Free";
    font-size: 13px;
    position: absolute;
    top: 15px;
    right: 5px;

    @include media("screen", ">=tablet") {
      content: none;
    }
  }

  &.not-collapsed {
    &:before {
      content: "\e873";
    }
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.accordion-block {
  @include media("screen", ">=tablet") {
    padding: 0 10px;
    width: 190px;
    margin-bottom: 20px;
  }

  @include media("screen", ">=widescreen") {
    margin-bottom: 0;
    width: 242px;
  }
}

.contacts-block {
  padding-bottom: 14px;
  @include media("screen", ">=tablet") {
    padding: 0 10px;
    width: 190px;
    margin-bottom: 20px;
  }

  @include media("screen", ">=desktop") {
    margin-bottom: 0;
  }

  @include media("screen", ">=widescreen") {
    width: 252px;
  }
}

.social-block {
  .block-heading {
    padding-bottom: 5px;

    @include media("screen", ">=tablet") {
      padding: 0 0 3px 0;
    }
  }

  @include media("screen", ">=tablet") {
    padding: 0 10px;
    width: 133px;
    margin-bottom: 20px;
  }

  @include media("screen", ">=desktop") {
    margin-bottom: 0;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    font-size: 20px;
    margin: -3px -7px 11px;

    li {
      margin: 0 7px;
    }
  }
}

.block-heading {
  display: block;
  font-size: 16px;
  font-weight: 400;
  padding-top: 13px;
  padding-bottom: 9px;
  border-top: 1px solid #e6e6e6;

  @include media("screen", ">=tablet") {
    padding: 0 0 3px 0;
    border: none;
  }
}

.flex-container {
  @include media("screen", ">=tablet") {
    padding: 0 14px;
  }
}

.social-rating-img {
  width: 120px;
  margin-left: -14px;

  img {
    width: 100%;
  }
}

.delivery,
.payment {
  @include media("screen", ">=tablet") {
    padding: 0 10px;
    width: 50%;
  }
  @include media("screen", ">=desktop") {
    width: 380px;
  }

  @include media("screen", ">=widescreen") {
    width: 484px;
  }

  ul {
    display: flex;
    align-items: center;
    padding-bottom: 21px;

    @include media("screen", ">=tablet") {
      padding-bottom: 0;
    }
  }

  .block-heading {
    padding-top: 17px;

    @include media("screen", ">=tablet") {
      padding: 0 0 3px 0;
      margin-bottom: 8px;
    }
  }
}

.delivery {
  li {
    + li {
      margin-left: 19px;

      @include media("screen", ">=tablet") {
        margin-left: 9px;
      }
    }
  }

  .img {
    width: 67px;
    display: block;

    @include media("screen", ">=tablet") {
      width: 42px;
    }

    img {
      width: 100%;
    }
  }
}

.payment {
  li {
    + li {
      margin-left: 11px;
    }
  }

  @include media("screen", ">=tablet") {
    order: 2;
  }
}

.delivery-and-payment {
  @include media("screen", ">=widescreen") {
    margin-top: -10px;
  }
}
</style>
