<template>
  <b-collapse
    :id="collapseId"
    class="sub-menu"
    :class="visible ? null : 'collapsed'"
    :aria-expanded="visible ? 'true' : 'false'"
    v-model="visible"
  >
    <ul v-for="menu in menuArray" :key="'menu' + menuArray.indexOf(menu)">
      <li v-for="menuItem in menu" :key="menuItem.id">
        <b-link v-on:click="closeInternal" :to="$link(menuItem.linkTo)">{{
          menuItem.name
        }}</b-link>
      </li>
    </ul>
  </b-collapse>
</template>

<script>
//import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "HeaderSubmenu",
  props: ["menuArray", "collapseId", "modelValue", "id"],
  data: () => ({
    //visible: false,
    me: this,
  }),
  computed: {
    visible: {
      get() {
        //Logger.debug("id", "HeaderSubMenu", this.id)();
        const vis = this.$store.getters["menu/getMenuItemState"](this.id);

        return vis.state;
      },
      set(val) {
        this.$store.commit("menu/updateMenuState", { id: this.id, state: val });
      },
    },
  },
  methods: {
    close(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.visible = false;
        //this.$store.commit("menu/setNavBar", false);
      }
    },
    closeInternal() {
      this.visible = false;
      this.$store.commit("menu/setNavBar", false);
    },
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  destroyed() {
    document.removeEventListener("click", this.close);
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/mixins.scss";
.sub-menu {
  position: relative !important;
  font-size: 15px;
  font-weight: 300;
  padding-left: 24px;

  @include media("screen", ">=desktop") {
    display: flex !important;
    flex-wrap: wrap;
    position: absolute !important;
    top: calc(100% + 9px);
    left: -8px;
    font-weight: 400;
    height: auto !important;
    background: white;
    padding: 24px 22px 24px 30px;
    min-width: 455px;
    border-radius: 0 0 5px 5px;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
  }

  @include media("screen", ">=widescreen") {
    min-width: 545px;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    @include media("screen", ">=desktop") {
      box-shadow: 0 9999px 0 9999px rgba(0, 0, 0, 0.7);
      display: flex !important;
    }
  }

  &.collapsing {
    display: flex !important;
    transition: all 0.7s ease-in;
  }

  ul {
    list-style: none;
    margin: 0;
    min-width: 185px;
    flex-shrink: 0;
    padding: 10px 0 0;

    @include media("screen", ">=desktop") {
      padding-top: 0;
    }

    li {
      + li {
        margin-top: 6px;

        @include media("screen", ">=desktop") {
          margin-top: 2px;
        }
      }

      &.active {
        a {
          color: #ff8000 !important;
        }
      }

      b-link {
        color: #000 !important;
        position: relative;
        &:hover {
          font-weight: 600;
          &:before {
            content: "";
            position: absolute;
            left: -23px;
            top: -4px;
            bottom: -3px;
            background: #ff8000;
            width: 3px;

            @include media("screen", ">=desktop") {
              content: none;
            }
          }

          @include media("screen", ">=desktop") {
            border-bottom: 2px solid #ff8000;
            font-weight: 400;
          }
        }
      }
    }

    + ul {
      padding-top: 5px;
      @include media("screen", ">=desktop") {
        margin-left: 30px;
        padding-top: 0;
      }

      @include media("screen", ">=widescreen") {
        margin-left: 50px;
      }
    }
    &:last-child {
      padding-bottom: 18px;

      @include media("screen", ">=desktop") {
        padding-bottom: 0;
      }
    }
  }
}
</style>
