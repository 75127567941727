<template>
  <b-collapse
    :id="index.toString()"
    :class="visible ? null : 'collapsed'"
    :aria-expanded="visible ? 'true' : 'false'"
    class="footer--drop"
    v-model="visible"
  >
    <cmsBlockSimple
      v-if="typeof accordionData == 'object'"
      :identifier="accordionData.identifier"
    />
  </b-collapse>
</template>

<script>
import cmsBlockSimple from "@/esf_lommel_dt1_europe/core/components//core/BlockSimple";

export default {
  name: "FooterAccordion",
  props: ["accordionData", "index"],
  components: { cmsBlockSimple },
  data: () => ({
    visible: false,
  }),
  methods: {
    close(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.visible = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  destroyed() {
    document.removeEventListener("click", this.close);
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.footer--drop {
  @include media("screen", ">=tablet") {
    display: block !important;
    transition: none !important;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0 0 15px;

  @include media("screen", ">=tablet") {
    padding: 0;
  }
}
</style>
