<template>
  <b-collapse
    :id="'topIconsCollapse' + showType + icon.iconNumber"
    :class="{ collapsed: visible }"
    :aria-expanded="visible"
    class="top-icons--drop"
    v-model="visible"
  >
    <span v-if="icon.dropHeading" class="drop-heading">{{
      icon.dropHeading
    }}</span>
    <ul class="drop-items">
      <li v-for="dropItem of icon.dropItems" :key="dropItem.dropItemId">
        <div class="d-flex align-items-start">
          <div class="drop-item--img">
            <b-img
              :src="dropItem.product.thumbnail.thumbnail"
              :alt="dropItem.product.name"
              width="39"
              height="39"
              v-if="
                dropItem.product.thumbnail.thumbnail != null &&
                !dropItem.product.thumbnail.thumbnail.match(/\/.jpg/gi)
              "
            >
            </b-img>
            <b-img
              :src="logoImg"
              :alt="dropItem.product.name"
              width="39"
              height="39"
              v-if="
                dropItem.product.thumbnail.thumbnail == null ||
                dropItem.product.thumbnail.thumbnail.match(/\/.jpg/gi)
              "
            >
            </b-img>
          </div>
          <div class="drop-item--info">
            <span class="product-name d-block">{{
              dropItem.product.name
            }}</span>
            <span v-if="typeof dropItem.configurable_options != 'undefined'">
              <span
                v-for="(option, index) of dropItem.configurable_options"
                :key="index"
                class="product-options d-block"
              >
                <span class="product-options-title"
                  >{{ option.option_label }}&nbsp;:</span
                >
                <span class="product-options-value">{{
                  option.value_label
                }}</span>
              </span>
            </span>
            <div class="quantity-price-box">
              <div class="price-box price">
                &euro;&nbsp;
                {{ dropItem.prices.row_total_including_tax.value.toFixed(2) }}
              </div>
              <div class="quantity-box">
                <div class="quantity-input d-flex">
                  <b-link
                    href="#"
                    class="step-down-btn"
                    @click="quantityDown(dropItem.id, dropItem.quantity)"
                  ></b-link>
                  <input
                    type="number"
                    min="1"
                    :value="dropItem.quantity"
                    name="quantity"
                    ref="inputQuantity"
                  />
                  <b-link
                    href="#"
                    class="step-up-btn"
                    @click="quantityUp(dropItem.id, dropItem.quantity)"
                  ></b-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          href="#"
          class="lnr lnr-cross"
          @click.prevent="removeItem(dropItem.id)"
        ></a>
      </li>
    </ul>
    <div class="d-flex drop-bottom align-items-center">
      <span class="total">
        {{ $t("total") }}: € {{ icon.cartFooter.totalPrice.toFixed(2) }}</span
      >
    </div>
    <div
      class="d-flex drop-bottom justify-content-between align-items-center"
      v-if="icon.cartFooter"
    >
      <b-link to="/checkout/cart">{{ $t("goto_cart") }}</b-link>
      <b-button
        variant="success"
        @click="$router.push('/checkout')"
        v-bind:disabled="btnDisable"
      >
        <i class="fa fa-check-circle"></i>
        <i class="lnr lnr-cart"></i>{{ icon.cartFooter.btnText }}
      </b-button>
    </div>
    <div class="language-block" v-if="icon.language">
      <ul class="d-flex list-unstyled">
        <li v-for="lang of icon.language" :key="lang.alt">
          <b-link :href="lang.href">
            <img :src="lang.img" :alt="lang.alt" />
          </b-link>
        </li>
      </ul>
    </div>
  </b-collapse>
</template>

<script>
//import { Logger } from "@storefront/core/lib/logger";
import Cart from "@storefront/core/modules/cart/mixins";
import logoImage from "@/esf_lommel_dt1_europe/assets/logo.png";

import { mapActions } from "vuex";
export default {
  name: "HeaderIconsDropdown",
  props: {
    icon: {
      type: Object,
    },
    showType: { type: String },
  },
  mixins: [Cart],
  computed: {
    btnDisable() {
      if (this.cartCount > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdownMenu);
  },
  destroyed() {
    document.removeEventListener("click", this.closeDropdownMenu);
  },
  data() {
    return {
      visible: false,
      logoImg: logoImage,
    };
  },
  methods: {
    ...mapActions(["setProductStatus"]),
    closeDropdownMenu(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.visible = false;
      }
    },
    closeOnClick() {
      this.visible = false;
    },
    removeItem(id) {
      this.$store.dispatch("cart/removeCartItem", {
        id: id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.collapsing {
  transition: none;
}

.quantity-box {
  position: relative;
  float: left;
}
.price-box {
  position: relative;
  float: right;
  text-align: right;
  padding-right: 10px;
}
.top-icons--drop {
  height: auto !important;
  position: absolute !important;
  z-index: 999;
  right: -91px;
  width: calc(100vw - 30px);
  min-width: 290px;
  max-width: 446px;
  top: calc(100% + 21px);
  background: white;
  padding: 17px 14px;
  text-align: left;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
  overflow: inherit !important;

  @include media("screen", ">=desktop") {
    width: 446px;
    max-width: none;
  }

  &#topIconsCollapse4 {
    right: -71px;

    &:before {
      right: 61px;
    }

    &:after {
      right: 72px;
    }
  }

  &#topIconsCollapse6 {
    width: auto;
    min-width: 0;
    right: -7px;
    padding: 17px 22px;

    @include media("screen", ">=tablet") {
      right: -20px;
    }

    &:before {
      right: 2px;

      @include media("screen", ">=tablet") {
        right: 10px;
      }
    }

    &:after {
      right: 14px;

      @include media("screen", ">=tablet") {
        right: 22px;
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: -16px;
    right: 85px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 16.5px 17px 16.5px;
    border-color: transparent transparent #ffffff transparent;
  }

  &:after {
    content: "";
    position: absolute;
    top: -11px;
    right: 108px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    z-index: -1;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.25);

    @include media("screen", ">=tablet") {
      top: -14px;
    }

    @include media("screen", ">=desktop") {
      box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.15);
    }
  }
}
.drop-item--info {
  width: 100%;
}
.drop-heading {
  display: block;
  font-size: 16px;
  color: #000;
  margin-bottom: 14px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: calc(100% + 14px);
    left: -14px;
    right: -14px;
    background: #f3f3f3;
    height: 1px;
  }
}

.drop-items {
  list-style: none;
  padding-left: 0;
  li {
    padding: 16px 25px 16px 14px;
    border-bottom: 1px solid #f3f3f3;
    position: relative;

    .lnr-cross {
      position: absolute;
      right: 3px;
      top: calc(50% + 2px);
      transform: translateY(-50%);
      font-size: 19px;
    }
  }
}

.drop-item--img {
  width: 46px;
  margin-right: 20px;

  img {
    width: 100%;
  }
}

.product-name {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 7px;
}

.price {
  font-size: 16px;
  font-weight: 500;
}

.drop-bottom {
  padding-top: 12px;

  .btn {
    padding: 7px 19px;
    font-size: 17px;
    position: relative;
    max-width: 250px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    i {
      margin-right: 20px;
      font-size: 20px;
    }
    .fa-check-circle {
      position: absolute;
      font-size: 12px;
      z-index: 9;
      top: 7px;
      left: 31px;
      background: #00893d;
      border-radius: 100%;
    }
  }

  .total {
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: #ff8000;
    padding-left: 2px;
    letter-spacing: 0.012em;
    margin-right: 10px;
    flex-shrink: 0;
  }
}

.language-block {
  li {
    + li {
      margin-left: 25px;
    }

    a {
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
.quantity-input {
  width: 73px;
  flex-shrink: 0;
  border: 1px solid #000;

  input[type="number"] {
    border: none;
    width: 15px;
    padding: 3px 0;
    margin: 0;
    font-size: 12px;
    font-family: "Work Sans";
    text-align: center;
    font-weight: 600;
    outline: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .step-down-btn,
  .step-up-btn {
    display: block;
    position: relative;
    width: 30px;
    flex-shrink: 0;
    font-size: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 13px;
      height: 1px;
      background: #000;
    }
  }

  .step-up-btn {
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 13px;
      background: #000;
    }
  }
  .quantity-price-box {
    margin-top: 5px;
  }
}
</style>
