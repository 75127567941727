<template>
  <div class="nav-form">
    <div class="d-md-none">
      <b-link class="lnr lnr-magnifier" @click.prevent="toggleSearch"></b-link>
    </div>
    <div class="search-group" :class="{ show: isSearchGroupShown }">
      <b-form-input
        v-model="search"
        type="text"
        required
        @blur="hideSearchResultsTimer()"
        :placeholder="$t('search_placeholder')"
        v-on:keyup.enter="goSearch()"
        class="search-input"
      ></b-form-input>
      <b-btn v-on:click="goSearch()" class="search-btn" variant="light" squared
        ><i class="lnr lnr-magnifier"></i
      ></b-btn>

      <div class="search-results" :class="{ show: isSearchResultsShown }">
        <ul class="search-suggestions">
          <li
            v-for="suggestion of searchSuggestions"
            :key="suggestion.word"
            class="d-block"
          >
            <b-link v-on:click="goRoute('/search', suggestion.word)">{{
              suggestion.word
            }}</b-link>
          </li>
        </ul>
        <span class="search--title">Aanbevolen producten</span>
        <ul class="recommended-products">
          <li
            v-for="recommendedProduct of recommendedProducts"
            :key="recommendedProduct.sku"
            class="d-inline-block"
          >
            <b-link
              v-on:click="goRoute('/' + recommendedProduct.url_key)"
              class="d-flex align-items-center"
            >
              <div class="product-img">
                <b-img
                  :src="getProductThumbnail(recommendedProduct)"
                  :alt="recommendedProduct.name"
                  width="41"
                  height="51"
                  fluid
                ></b-img>
              </div>
              <span class="product-name">{{ recommendedProduct.name }}</span>
            </b-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductsBySearchPreview } from "@storefront/core/data-resolver/products";
import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "HeaderSearch",
  data: () => ({
    search: "",
    searchlTimer: null,
    seachCloseTimer: null,
    output: null,
    searchSuggestions: [],
    recommendedProducts: [],
    isSearchGroupShown: false,
    isSearchResultsShown: false,
  }),
  computed: {},

  mounted() {
    window.addEventListener("resize", this.resize);
    document.addEventListener("click", this.close);
  },
  destroyed() {
    document.removeEventListener("click", this.close);
    window.removeEventListener("click", this.resize);
  },
  methods: {
    resize() {
      document.body.classList.add("resize-active");
      if (window.innerWidth > 575) {
        this.isSearchGroupShown = false;
      }
      clearTimeout(this.resizeTimer);
      this.resizeTimer();
    },
    resizeTimer() {
      setTimeout(() => {
        document.body.classList.remove("resize-active");
      }, 400);
    },
    toggleSearch() {
      this.isSearchGroupShown = !this.isSearchGroupShown;
    },
    close(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.isSearchGroupShown = false;
      }
    },
    showSearchResults() {
      this.isSearchResultsShown = true;
    },
    hideSearchResults() {
      this.isSearchResultsShown = false;
    },
    async getSearchPreview() {
      this.output = await getProductsBySearchPreview(this.search);
      this.recommendedProducts = this.output.items;
      this.searchSuggestions = this.output.search_suggestions;
      this.showSearchResults();
    },
    goRoute(route, query = null) {
      Logger.debug("goRoute", "HeaderSearch", route)();
      this.hideSearchResults();
      this.search = "";
      if (query != null) {
        this.$router.push({ path: route, query: { q: query } });
      } else {
        this.$router.push({ path: route });
      }
    },
    goSearch() {
      this.hideSearchResults();
      if (this.search.length > 0) {
        this.$router.push({ path: "/search", query: { q: this.search } });
      }
    },
    hideSearchResultsTimer() {
      Logger.debug("hideSearchResultsTimer", "HeaderSearch", null)();
      this.seachCloseTimer = setTimeout(
        function (scope) {
          scope.search = "";
          scope.hideSearchResults();
        },
        300,
        this
      );
    },
    getProductThumbnail(product) {
      var regex = /\/\.jpg$/;
      if (product.thumbnail.small.match(regex)) {
        return product.thumbnail.url;
      } else {
        return product.thumbnail.small;
      }
    },
  },
  watch: {
    search() {
      if (this.search.length > 2) {
        if (this.searchlTimer !== null) {
          clearTimeout(this.searchlTimer);
        }
        this.searchlTimer = setTimeout(
          function (scope) {
            scope.getSearchPreview();
          },
          300,
          this
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.oppesit {
  color: black;
  display: block;
  &:hover {
    color: gray !important;
  }
}

.normal {
  color: #fff;
  display: block;
  &:hover {
    color: gray !important;
  }
}

.nav-form {
  flex-grow: 1;
  margin-left: 16px;
  font-size: 20px;
  margin-top: -5px;

  @include media("screen", ">=desktop") {
    margin-left: 0;
    position: relative;
  }
}
.search-group {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  display: none;
  background: white;
  z-index: 9;

  @include media("screen", ">=tablet") {
    display: block;
    position: relative;
    left: auto;
    top: auto;
    width: auto;
  }

  &.show {
    display: block;
  }
}
.search-form {
  font-size: 13px;
  position: relative;
  display: flex;

  .search-input {
    font-size: 15px;
    padding-right: 45px;
    border-radius: 4px;
  }

  ::placeholder {
    font-size: 13px;
    font-family: "Work Sans";
    letter-spacing: -0.02em;
  }
}

.search-btn {
  background: none !important;
  border: none !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  font-size: 20px;
  padding: 4px 12px;
}

.search-results {
  position: absolute;
  // left: 0;
  right: 0;
  top: 100%;
  min-width: 290px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  z-index: 999;
  display: none;
  background: white;
  font-size: 15px;

  @include media("screen", ">=phone") {
    left: 0;
  }

  &.show {
    display: block;
  }
}

.search-suggestions,
.recommended-products {
  list-style: none;
  padding: 7px 15px 14px;
  margin: 0;
}

.search-suggestions {
  border-bottom: 1px solid #dfdfdf;
}

.search--title {
  font-size: 16px;
  font-weight: normal;
  display: block;
  padding: 9px 15px 6px;
  color: #0d1f47;
}

.recommended-products {
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 22px;

  .product-img {
    margin-right: 15px;
    width: 41px;
    flex-shrink: 0;

    img {
      width: 100%;
    }
  }

  li {
    + li {
      margin-top: 18px;
    }
  }
}
</style>
