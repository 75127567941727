<template>
  <transition name="back-to-top-fade">
    <client-only>
      <div
        class="vue-back-to-top"
        :style="`bottom:${this.bottom};right:${this.right};`"
        v-show="visible"
        @click="backToTop"
      >
        <slot>
          <div class="default">
            <span>
              {{ text }}
            </span>
          </div>
        </slot>
      </div>
    </client-only>
  </transition>
</template>

<script>
import ClientOnly from "vue-client-only";
export default {
  name: "BackToTop",
  components: {
    ClientOnly,
  },
  props: {
    text: {
      type: String,
      default: "Voltar ao topo",
    },
    visibleoffset: {
      type: [String, Number],
      default: 600,
    },
    visibleoffsetbottom: {
      type: [String, Number],
      default: 0,
    },
    right: {
      type: String,
      default: "30px",
    },
    bottom: {
      type: String,
      default: "40px",
    },
    scrollFn: {
      type: Function,
      default: function () {},
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    window.smoothscroll = () => {
      const currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(window.smoothscroll);
        window.scrollTo(0, Math.floor(currentScroll - currentScroll / 5));
      }
    };
    window.addEventListener("scroll", this.catchScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.catchScroll);
  },
  methods: {
    /**
     * Catch window scroll event
     * @return {void}
     */
    catchScroll() {
      const pastTopOffset = window.pageYOffset > parseInt(this.visibleoffset);
      const pastBottomOffset =
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - parseInt(this.visibleoffsetbottom);
      this.visible =
        parseInt(this.visibleoffsetbottom) > 0
          ? pastTopOffset && !pastBottomOffset
          : pastTopOffset;
      this.scrollFn(this);
    },
    /**
     * The function who make the magics
     * @return {void}
     */
    backToTop() {
      window.smoothscroll();
      this.$emit("scrolled");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../@/esf_lommel_dt1_europe/scss/main.scss";
.back-to-top-fade-enter-active,
.back-to-top-fade-leave-active {
  transition: opacity 0.7s;
}
.back-to-top-fade-enter,
.back-to-top-fade-leave-to {
  opacity: 0;
}

.vue-back-to-top {
  cursor: pointer;
  position: fixed;
  z-index: 1000;
}

.vue-back-to-top .default {
  visibility: collapse;
  background-color: #f5c85c;
  border-radius: 3px;
  color: #ffffff;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 160px;
}

.vue-back-to-top .default span {
  color: #ffffff;
}

.vue-back-to-top--is-footer {
  bottom: 50% !important;
  position: absolute;
  transform: translateY(50%);
}

.back-to-top {
  @include animate(opacity);
  width: 48px;
  height: 48px;
  background: #000;
  border-radius: 100%;
  font-size: 0;
  line-height: 0;
  // position: absolute;
  // bottom: auto;
  // top: -40px;
  // right: 40px !important;

  // @include media('screen', '>=desktop') {
  //   top: -60px;
  // }

  @include media("screen", ">=#{map-get($container-max-widths, xl)}") {
    right: calc((100vw - #{map-get($container-max-widths, xl)}) / 4) !important;
  }

  &:after {
    content: "";
    width: 18px;
    height: 18px;
    border-top: 4px solid #fff;
    border-right: 4px solid #fff;
    transform: translate(-50%, -50%) rotate(-45deg);
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: 4px;
  }

  &:hover {
    opacity: 0.7;
  }
}
</style>
