<template>
  <section class="separator text-light d-none d-lg-block">
    <div class="container">
      <div class="d-flex align-items-center justify-content-between bg-dark">
        <div>
          <div class="icon d-inline-block align-middle">
            <b-img
              src="@/esf_lommel_dt1_europe/assets/icon-clock-co.png"
              width="24"
              height="25"
              alt="Delivery"
              fluid
            ></b-img>
          </div>
          <!-- <span class="d-inline-block align-middle"
            ><cmsBlockSimpleTitle identifier="order_today_tomorrow_at_home"
          /></span> -->
        </div>
        <div class="mx-10">
          <div class="icon d-inline-block align-middle">
            <b-img
              src="@/esf_lommel_dt1_europe/assets/icon-delivery-co.png"
              width="24"
              height="25"
              alt="Delivery"
              fluid
            ></b-img>
          </div>
          <span class="d-inline-block align-middle"
            ><cmsBlockSimpleTitle identifier="free_shipping"
          /></span>
        </div>
        <div>
          <div class="icon d-inline-block align-middle">
            <b-img
              src="@/esf_lommel_dt1_europe/assets/icon-tick-co.png"
              width="18"
              height="18"
              alt="Tick"
              fluid
            ></b-img>
          </div>
          <!-- <span class="d-inline-block align-middle"
            ><cmsBlockSimpleTitle identifier="our_advantages"
          /></span> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import cmsBlockSimpleTitle from "@/esf_lommel_dt1_europe/core/components//core/BlockSimpleTitle";

export default {
  name: "FooterDeliverySeparator",
  components: { cmsBlockSimpleTitle },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.separator {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  background-image: url("@/esf_lommel_dt1_europe/assets/Cyclewearonline-dl.svg");
  background-size: auto 100%;
  background-position: 0 0;
  margin-top: 30px;
  margin-bottom: 30px;

  @include media("screen", ">=desktop") {
    padding: 2px 0;
    margin-top: 42px;
    margin-bottom: 23px;
  }

  .bg-dark {
    display: inline-block;
    position: relative;
    z-index: 0;
    line-height: 1.35;
    margin-left: 11px;
    margin-right: 12px;
    padding: 9px 17px;
    background: white;
    color: white;

    &:before {
      content: "";
      position: absolute;
      left: -11px;
      right: -12px;
      top: -3px;
      bottom: -3px;
      background: white;
      z-index: -2;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: -2px;
      bottom: -2px;
      background: black;
      z-index: -1;

      @include media("screen", ">=desktop") {
        left: -1px;
        right: -2px;
      }
    }
  }

  .icon {
    max-height: 25px;
    margin-right: 12px;

    img {
      width: 100%;
    }
  }
}
</style>
