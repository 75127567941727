<template>
  <b-collapse
    :id="'topIconsCollapse' + showType + icon.iconNumber"
    :class="{ collapsed: visible }"
    :aria-expanded="visible"
    class="top-icons--drop"
    v-model="visible"
  >
    <span v-if="icon.dropHeading" class="drop-heading">{{
      icon.dropHeading
    }}</span>
    <ul class="drop-items">
      <li v-for="(dropItem, index) of icon.dropItems" :key="`wl-${index}`">
        <b-link
          :to="`/${dropItem.product.url_key}`"
          class="d-flex align-items-start"
          @click="closeOnClick"
        >
          <div class="drop-item--img">
            <img
              :src="dropItem.product.image.url"
              :alt="dropItem.product.sku"
            />
          </div>
          <div class="drop-item--info">
            <span class="product-name d-block">{{
              dropItem.product.name
            }}</span>
            <span class="price d-block"
              >&euro;{{
                dropItem.product.price_range.minimum_price.final_price.value.toFixed(
                  2
                )
              }}</span
            >
          </div>
        </b-link>
        <a
          href="#"
          class="lnr lnr-cross"
          @click.prevent="removeItem(dropItem.id)"
        ></a>
      </li>
    </ul>
    <div
      class="d-flex drop-bottom justify-content-between align-items-center"
      v-if="icon.cartFooter"
    >
      <span class="total">Total: € {{ icon.cartFooter.totalPrice }}</span>
      <b-button variant="success">
        <i class="fa fa-check-circle"></i>
        <i class="lnr lnr-cart"></i>{{ icon.cartFooter.btnText }}
      </b-button>
    </div>
    <div class="language-block" v-if="icon.language">
      <ul class="d-flex list-unstyled">
        <li v-for="lang of icon.language" :key="lang.alt">
          <b-link :to="lang.href"
            ><b-img
              :src="lang.img"
              :alt="lang.lang"
              width="39"
              height="39"
              fluid
            ></b-img>
          </b-link>
        </li>
      </ul>
    </div>
  </b-collapse>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "HeaderIconsDropdown",
  props: {
    icon: {
      type: Object,
    },
    showType: { type: String },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdownMenu);
  },
  destroyed() {
    document.removeEventListener("click", this.closeDropdownMenu);
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    ...mapActions(["setProductStatus"]),
    closeDropdownMenu(e) {
      if (!this.$el.parentElement.contains(e.target)) {
        this.visible = false;
      }
    },
    removeItem(id) {
      this.$store.dispatch("user/removeProductWishlist", {
        id: id,
      });
    },
    closeOnClick() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.collapsing {
  transition: none;
}

.top-icons--drop {
  height: auto !important;
  position: absolute !important;
  z-index: 999;
  right: -91px;
  width: calc(100vw - 30px);
  min-width: 290px;
  max-width: 446px;
  top: calc(100% + 21px);
  background: white;
  padding: 17px 14px;
  text-align: left;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
  overflow: inherit !important;

  @include media("screen", ">=desktop") {
    width: 446px;
    max-width: none;
  }

  &#topIconsCollapse4 {
    right: -71px;

    &:before {
      right: 61px;
    }

    &:after {
      right: 72px;
    }
  }

  &#topIconsCollapse6 {
    width: auto;
    min-width: 0;
    right: -7px;
    padding: 17px 22px;

    @include media("screen", ">=tablet") {
      right: -20px;
    }

    &:before {
      right: 2px;

      @include media("screen", ">=tablet") {
        right: 10px;
      }
    }

    &:after {
      right: 14px;

      @include media("screen", ">=tablet") {
        right: 22px;
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: -16px;
    right: 97px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 16.5px 17px 16.5px;
    border-color: transparent transparent #ffffff transparent;
  }

  &:after {
    content: "";
    position: absolute;
    top: -11px;
    right: 108px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    z-index: -1;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.25);

    @include media("screen", ">=tablet") {
      top: -14px;
    }

    @include media("screen", ">=desktop") {
      box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.15);
    }
  }
}

.drop-heading {
  display: block;
  font-size: 16px;
  color: #000;
  margin-bottom: 14px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: calc(100% + 14px);
    left: -14px;
    right: -14px;
    background: #f3f3f3;
    height: 1px;
  }
}

.drop-items {
  list-style: none;
  padding-left: 0;
  li {
    padding: 16px 25px 16px 14px;
    border-bottom: 1px solid #f3f3f3;
    position: relative;

    .lnr-cross {
      position: absolute;
      right: 3px;
      top: calc(50% + 2px);
      transform: translateY(-50%);
      font-size: 19px;
    }
  }
}

.drop-item--img {
  width: 46px;
  margin-right: 20px;

  img {
    width: 100%;
  }
}

.product-name {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 7px;
}

.price {
  font-size: 16px;
  font-weight: 500;
}

.drop-bottom {
  padding-top: 12px;

  .btn {
    padding: 7px 19px;
    font-size: 17px;
    position: relative;
    max-width: 250px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    i {
      margin-right: 20px;
      font-size: 20px;
    }
    .fa-check-circle {
      position: absolute;
      font-size: 12px;
      z-index: 9;
      top: 7px;
      left: 31px;
      background: #00893d;
      border-radius: 100%;
    }
  }

  .total {
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: #ff8000;
    padding-left: 2px;
    letter-spacing: 0.012em;
    margin-right: 10px;
    flex-shrink: 0;
  }
}

#topIconsCollapse6 {
}

.language-block {
  li {
    + li {
      margin-left: 25px;
    }

    a {
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
